import React from 'react';
import './App.css';
import config from "./config";
import MicrosoftLogin from "react-microsoft-login";
import axios from 'axios';
import mergeImages from 'merge-images';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


class App extends React.Component {

  constructor(props) {
    super(props);
    this.login = React.createRef();
    this.slider = React.createRef();
    this.state = {
      token: undefined,
      registered: false,
      url: window.location.href
    }
    this.updatePhoto = this.updatePhoto.bind(this);
    this.updateImages = this.updateImages.bind(this);
  }

  updateImage(filterImage, opacity, target) {
    mergeImages([{ src: this.originalImg.src }, { src: filterImage, opacity: opacity }], { format: 'image/jpeg', quality: 40 })
      .then(b64 => target.src = b64);
  }

  updateImages() {
    this.updateImage(this.resizedProgress, this.slider.current.state.value, this.progressImg);

    this.updateImage(this.resizedRainbow, this.slider.current.state.value, this.rainbowImg);
  }

  componentDidUpdate() {
    if (this.state.token) {
      //let url = 'https://graph.microsoft.com/beta/users/timothee.vallin@ihsmarkit.com/photo/$value'
      let url = 'https://graph.microsoft.com/beta/me/photo/$value';
      axios.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + this.state.token,
            'Content-Type': 'image/jpg'
          },
          responseType: 'arraybuffer'
        }).then((res) => {

          let base64 = Buffer.from(res.data, 'binary').toString('base64')
          this.originalImg.src = "data:image/jpg;base64," + base64;
          let tmpImage = new Image();
          let that = this;
          tmpImage.onload = function () {
            let targetWidth = this.width;
            let targetHeight = this.height;

            let progressImg = new Image();
            progressImg.onload = function () {
              let canvas = document.createElement('canvas');
              let ctx = canvas.getContext('2d');

              // set its dimension to target size
              canvas.width = targetWidth;
              canvas.height = targetHeight;

              // draw source image into the off-screen canvas:
              ctx.drawImage(this, 0, 0, targetWidth, targetHeight);
              // ctx.drawImage(this, -20, -15, targetWidth, targetHeight);
              // encode image to data-uri with base64 version of compressed image
              that.resizedProgress = canvas.toDataURL();
              that.updateImage(that.resizedProgress, 0.60, that.progressImg);

            }
            progressImg.src = './bca.png';
          }
          tmpImage.src = this.originalImg.src;
        });
    }
  }

  updatePhoto() {
    this.downloadBase64File(this.originalImg, 'me.jpg');
    this.downloadBase64File(this.progressImg, 'progress_me.jpg');
    axios.get('/photo_downloaded.txt').then((res) => {
      console.log(res);
    });
  }

  downloadBase64File(image, fileName) {

    if (window.navigator && window.navigator.msSaveBlob) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, image.width, image.height);
      window.navigator.msSaveBlob(canvas.msToBlob(), fileName);

    }
    else {
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = image.src;
      downloadLink.target = '_self';
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  b64toBlob(b64Data, contentType = 'image/jpeg', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  signUp() {
    axios({
      method: 'post', url: '/becomeAnAlly/',
      headers: { 'Content-Type': 'application/json' }, data: {
        email: this.state.email
      }
    }).then((res) => {
      const newState = {
        token: this.state.token,
        registered: true,
        email: this.state.email,
        displayName: this.state.displayName
      }
      this.setState(newState);
    });
  }

  render() {
    /*
  
    */
    const authHandler = (err, data) => {
      console.log(data);
      let url2 = 'https://graph.microsoft.com/beta/me';
      axios.get(url2,
        { headers: { 'Authorization': 'Bearer ' + data.accessToken } }).then((res) => {
          this.setState({ token: data.accessToken, email: data.account.username, displayName: data.account.name, registered: false });
        });
    };
    if (this.state.token) {
      return (
        <div>
          <div class="logo2"><img src="/images/IHS-Markit-logo.png" width="145px" align="right" /></div>
          <div class="logo1"> <img src="/images/IHSMarkitBCA.png" width="400px" align="left" /></div>
          <div class="becoming1">
            <img src="/IHSBCAMarkitBanner.jpg" width="32%" />
          </div>
          <div className="App">
            <p align="left">October is Breast Cancer Awareness Month, an annual campaign to raise awareness about the impact of breast cancer. Join us as we RISE together to help uplift women in need.
            </p>
            <p>We @ IHS Markit want to encourage our colleagues especially females to help spread awareness about the symptoms and diagnosis of the disease. If diagnosed early and treated before it spreads, five-year survival rate for breast cancer is 99 percent.
            </p>
            <p>Let us come together and support the spirit of the event by using BCA version pictures on our profiles.</p>
          </div>
          <div className="App">
            <img width="120px" src={""} alt={"Loading..."} ref={(e) => this.originalImg = e} />
            <img width="120px" src={""} alt={"Loading..."} ref={(e) => this.progressImg = e} />
          </div>
          <div className="App">
            <Slider ref={this.slider} min={0} max={1} step={0.1} defaultValue={0.60} onAfterChange={this.updateImages}></Slider>
          </div>
          <div className="App">
            Use the slider to adjust the opacity of the flag.
          </div>
          <div className="App">
            <button className="button w-button accept" onClick={this.updatePhoto}>Click here to download my photos</button>
          </div>
          <div className="App">
            <p ><i>To update your photo:</i></p>
            <p><i>Open Teams => Click on your current profile photo displayed at the top right => Thereafter, click on your Profile Picture (Change Profile Photo) => Select "Upload Picture" =>  Select your saved image => "Save" to implement changes.</i></p>
          </div>
          <div class="pride"><span>BCA@IHS Markit: Breast Cancer Awareness</span></div>
        </div>

      );
    }
    else {
      return (
        <div className="App">
          <div class="logo2"><img src="/images/IHS-Markit-logo.png" width="145px" align="right" /></div>
          <div class="logo1"> <img src="/images/IHSMarkitBCA.png" width="520px" align="left" /></div>
          <br />
          <div className="becoming1">
            Please login to continue
          </div>

          <div className="becoming1">
            <MicrosoftLogin ref={this.login} clientId={config.client_id} authCallback={authHandler} graphScopes={['User.Read']} />
          </div>
        </div>
      );
    }

  }
}

export default App;
